import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const Conditions = () => {
  const location = useLocation();

  const navigate = useNavigate();

  const handleClickBack = () => {
    navigate('/calculator/result/request', {
      state: location.state,
    });
  };

  var WebApp = window.Telegram.WebApp;
  var BackButton = WebApp.BackButton;
  BackButton.show();
  BackButton.onClick(function () {
    navigate('/calculator/result/request', {
      state: location.state,
    });
    BackButton.hide();
  });

  return (
    <div className='feedback'>
      <div className='conditionsGray'>
        <div className='feedbackTop'>
          <h2>Условия обработки персональных данных</h2>
          <div className='conditions'>
            <div>
              <p>
                Предоставляя свои персональные данные Пользователь даёт согласие
                на обработку, хранение и использование своих персональных данных
                на основании ФЗ № 152-ФЗ «О персональных данных» от 27.07.2006
                г. в следующих целях:
                <ul className='conditionsUl'>
                  <li>Осуществление клиентской поддержки</li>
                  <li>
                    Получения Пользователем информации о маркетинговых событиях
                  </li>
                  <li>
                    Проведения аудита и прочих внутренних исследований с целью
                    повышения качества предоставляемых услуг.
                  </li>
                </ul>
              </p>
            </div>
            <div>
              <p>
                Под персональными данными подразумевается любая информация
                личного характера, позволяющая установить личность
                Пользователя/Покупателя такая как:
                <ul className='conditionsUl'>
                  <li>Фамилия, Имя, Отчество</li>
                  <li>Дата рождения</li>
                  <li>Контактный телефон</li>
                  <li>Адрес электронной почты</li>
                  <li>Почтовый адрес</li>
                </ul>
              </p>
            </div>
            <div>
              <p>
                Персональные данные Пользователей хранятся исключительно на
                электронных носителях и обрабатываются с использованием
                автоматизированных систем, за исключением случаев, когда
                неавтоматизированная обработка персональных данных необходима в
                связи с исполнением требований законодательства.
              </p>
            </div>
            <div>
              <p>
                Компания обязуется не передавать полученные персональные данные
                третьим лицам, за исключением следующих случаев:
                <ul className='conditionsUl'>
                  <li>
                    По запросам уполномоченных органов государственной власти РФ
                    только по основаниям и в порядке, установленным
                    законодательством РФ
                  </li>
                  <li>
                    Стратегическим партнерам, которые работают с Компанией для
                    предоставления продуктов и услуг, или тем из них, которые
                    помогают Компании реализовывать продукты и услуги
                    потребителям. Мы предоставляем третьим лицам минимальный
                    объем персональных данных, необходимый только для оказания
                    требуемой услуги или проведения необходимой транзакции.
                  </li>
                </ul>
              </p>
            </div>
            <div>
              <p>
                Компания оставляет за собой право вносить изменения в
                одностороннем порядке в настоящие правила, при условии, что
                изменения не противоречат действующему законодательству РФ.
                Изменения условий настоящих правил вступают в силу после их
                публикации на Сайте.
              </p>
            </div>
          </div>
          <div className='kargoCalculate'>
            <button
              type='submit'
              className='kargoCalculateBtn'
              onClick={handleClickBack}
            >
              Понятно
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Conditions;
