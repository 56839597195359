import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Request from './pages/Request';
import ScrollToTop from './components/ScrollToTop.js';
import Feedback from './pages/Feedback.js';
import Conditions from './pages/Conditions.js';
import RequestSubmitted from './pages/RequestSubmitted.js';
import Calculator from './pages/Calculator.js';
import PackagingTariffs from './pages/PackagingTariffs.js';
import InsuranceTariffs from './pages/InsuranceTariffs.js';
import DeliveryTarrifs from './pages/DeliveryTarrifs.js';

function App() {
  var WebApp = window.Telegram.WebApp;
  WebApp.enableClosingConfirmation();

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path='/'
          element={
            <>
              <Home /> <ScrollToTop />
            </>
          }
        ></Route>
        <Route
          path='/calculator'
          element={
            <>
              <Calculator /> <ScrollToTop />
            </>
          }
        ></Route>
        <Route
          path='/calculator/result'
          element={
            <>
              <Request /> <ScrollToTop />
            </>
          }
        ></Route>
        <Route
          path='/calculator/result/request'
          element={
            <>
              <Feedback /> <ScrollToTop />
            </>
          }
        ></Route>
        <Route
          path='/calculator/result/request/conditions'
          element={
            <>
              <Conditions /> <ScrollToTop />
            </>
          }
        ></Route>
        <Route
          path='/calculator/result/request/submit'
          element={
            <>
              <RequestSubmitted /> <ScrollToTop />
            </>
          }
        ></Route>
        <Route
          path='/calculator/package'
          element={
            <>
              <PackagingTariffs /> <ScrollToTop />
            </>
          }
        ></Route>
        <Route
          path='/calculator/insurance'
          element={
            <>
              <InsuranceTariffs /> <ScrollToTop />
            </>
          }
        ></Route>
        <Route
          path='/calculator/delivery'
          element={
            <>
              <DeliveryTarrifs /> <ScrollToTop />
            </>
          }
        ></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
