import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import containerBlack from '../img/containerBlack.svg';
import containerOrange from '../img/containerOrange.svg';
import alertRed from '../img/alertRed.svg';

const WhiteDelivery = () => {
  const [containerType, setContainerType] = useState('20 ft');
  const [cargoDescription, setCargoDescription] = useState(null);
  const [weight, setWeight] = useState(0);
  const [volume, setVolume] = useState(0);
  const [deliveryConditions, setDeliveryConditions] = useState('FOB');

  const [shippingPort, setShippingPort] = useState('Шанхай');
  const [portOfDestination, setPortOfDestination] = useState('Владивосток');
  const [departureAddress, setDepartureAddress] = useState(null);
  const [destinationAddress, setDestinationAddress] = useState(null);
  const [taskDescription, setTaskDescription] = useState(null);

  const [agency, setAgency] = useState(null);
  const [customsClearance, setCustomsClearance] = useState(null);

  const [dontKnowAddress, setDontKnowAddress] = useState(false);

  const [otherInfo, setOtherInfo] = useState(null);

  const [isCargoDescriptionNull, setIsCargoDescriptionNull] = useState(false);
  const [isWeightNull, setIsWeightNull] = useState(false);
  const [isVolumeNull, setIsVolumeNull] = useState(false);
  const [isDepartureAddressNull, setIsDepartureAddressNull] = useState(false);
  const [isDestinationAddressNull, setIsDestinationAddressNull] =
    useState(false);

  const handleCheck = () => {
    if (cargoDescription === null || cargoDescription === '') {
      setIsCargoDescriptionNull(true);
    }
    if (weight === 0 || weight === '' || weight < 0) {
      setIsWeightNull(true);
    }
    if (volume === 0 || volume === '' || volume < 0) {
      setIsVolumeNull(true);
    }
    if (departureAddress === null || departureAddress === '') {
      setIsDepartureAddressNull(true);
    }
    if (destinationAddress === null || destinationAddress === '') {
      setIsDestinationAddressNull(true);
    }
  };

  const navigate = useNavigate();

  function handleSubmit(e) {
    e.preventDefault();
    if (containerType === '20 ft' || containerType === '40 ft') {
      if (
        deliveryConditions === 'FOB' ||
        deliveryConditions === 'FCA' ||
        deliveryConditions === 'CFR' ||
        deliveryConditions === 'CRT' ||
        deliveryConditions === 'Не знаю'
      ) {
        if (
          !isCargoDescriptionNull &&
          !isWeightNull &&
          !isVolumeNull &&
          !isDestinationAddressNull
        )
          navigate('/calculator/result/request/submit');
      } else if (deliveryConditions === 'EXW') {
        if (
          !isCargoDescriptionNull &&
          !isWeightNull &&
          !isVolumeNull &&
          !isDestinationAddressNull &&
          !isDepartureAddressNull
        )
          navigate('/calculator/result/request/submit');
      }
    } else if (containerType === 'Сборка') {
      if (dontKnowAddress) {
        if (
          !isCargoDescriptionNull &&
          !isWeightNull &&
          !isVolumeNull &&
          !isDestinationAddressNull
        )
          navigate('/calculator/result/request/submit');
      } else {
        if (
          !isCargoDescriptionNull &&
          !isWeightNull &&
          !isVolumeNull &&
          !isDestinationAddressNull &&
          !isDepartureAddressNull
        )
          navigate('/calculator/result/request/submit');
      }
    }
  }

  let deliveryDecr;
  if (deliveryConditions === 'FOB') {
    deliveryDecr = 'Доставка до порта в Китае за счёт поставщика';
  } else if (deliveryConditions === 'EXW') {
    deliveryDecr = 'Самовывоз со склада поставщика за счёт покупателя.';
  } else if (deliveryConditions === 'FCA') {
    deliveryDecr = 'Доставка до нашего склада за счёт поставщика';
  } else if (deliveryConditions === 'CFR') {
    deliveryDecr = 'Доставка и таможня до склада за счёт поставщика';
  } else if (deliveryConditions === 'CRT') {
    deliveryDecr = 'Доставка и таможня до склада за счёт поставщика';
  }

  let address;
  if (deliveryConditions === 'FOB') {
    address = (
      <>
        <div className='whiteDeliveryDescrAndInput'>
          <p className='whiteDeliveryP'>Порт отгрузки</p>
          <div className='kargoCityBtns'>
            <button
              type='button'
              onClick={() => {
                setShippingPort('Шанхай');
              }}
              style={{
                background: shippingPort === 'Шанхай' ? '#131416' : '#E0E8F1',
                color: shippingPort === 'Шанхай' ? '#FF9900' : '#131416',
              }}
            >
              Шанхай
            </button>
            <button
              type='button'
              onClick={() => {
                setShippingPort('Нинбо');
              }}
              style={{
                background: shippingPort === 'Нинбо' ? '#131416' : '#E0E8F1',
                color: shippingPort === 'Нинбо' ? '#FF9900' : '#131416',
              }}
            >
              Нинбо
            </button>
            <button
              type='button'
              onClick={() => {
                setShippingPort('Циндао');
              }}
              style={{
                background: shippingPort === 'Циндао' ? '#131416' : '#E0E8F1',
                color: shippingPort === 'Циндао' ? '#FF9900' : '#131416',
              }}
            >
              Циндао
            </button>
            <button
              type='button'
              onClick={() => {
                setShippingPort('Наньша');
              }}
              style={{
                background: shippingPort === 'Наньша' ? '#131416' : '#E0E8F1',
                color: shippingPort === 'Наньша' ? '#FF9900' : '#131416',
              }}
            >
              Наньша
            </button>
            <button
              type='button'
              onClick={() => {
                setShippingPort('Тяньцзинь');
              }}
              style={{
                background:
                  shippingPort === 'Тяньцзинь' ? '#131416' : '#E0E8F1',
                color: shippingPort === 'Тяньцзинь' ? '#FF9900' : '#131416',
              }}
            >
              Тяньцзинь
            </button>
            <button
              type='button'
              onClick={() => {
                setShippingPort('Сямынь');
              }}
              style={{
                background: shippingPort === 'Сямынь' ? '#131416' : '#E0E8F1',
                color: shippingPort === 'Сямынь' ? '#FF9900' : '#131416',
              }}
            >
              Сямынь
            </button>
          </div>
        </div>
        <div className='whiteDeliveryDescrAndInput'>
          <p className='whiteDeliveryP'>Порт назначения в РФ</p>
          <div className='kargoCityBtns'>
            <button
              type='button'
              onClick={() => {
                setPortOfDestination('Владивосток');
              }}
              style={{
                background:
                  portOfDestination === 'Владивосток' ? '#131416' : '#E0E8F1',
                color:
                  portOfDestination === 'Владивосток' ? '#FF9900' : '#131416',
              }}
            >
              Владивосток
            </button>
            <button
              type='button'
              onClick={() => {
                setPortOfDestination('Восточный');
              }}
              style={{
                background:
                  portOfDestination === 'Восточный' ? '#131416' : '#E0E8F1',
                color:
                  portOfDestination === 'Восточный' ? '#FF9900' : '#131416',
              }}
            >
              Восточный
            </button>
            <button
              type='button'
              onClick={() => {
                setPortOfDestination('Новороссийск');
              }}
              style={{
                background:
                  portOfDestination === 'Новороссийск' ? '#131416' : '#E0E8F1',
                color:
                  portOfDestination === 'Новороссийск' ? '#FF9900' : '#131416',
              }}
            >
              Новороссийск
            </button>
            <button
              type='button'
              onClick={() => {
                setPortOfDestination('Санкт-Петербург');
              }}
              style={{
                background:
                  portOfDestination === 'Санкт-Петербург'
                    ? '#131416'
                    : '#E0E8F1',
                color:
                  portOfDestination === 'Санкт-Петербург'
                    ? '#FF9900'
                    : '#131416',
              }}
            >
              Санкт-Петербург
            </button>
          </div>
        </div>
        <div className='whiteDeliveryDescrAndInput'>
          <p className='whiteDeliveryP'>Адрес назначения в РФ</p>
          <input
            type='text'
            className='otherCategoryInput'
            placeholder='Например, Москва'
            maxLength={25}
            onChange={(e) => {
              setDestinationAddress(e.target.value);
              setIsDestinationAddressNull(false);
            }}
            style={{
              border: isDestinationAddressNull
                ? '2px solid #FF4D00'
                : '2px solid #e0e8f1',
            }}
          />
          {isDestinationAddressNull ? (
            <div className='fillingError'>
              <img src={alertRed} alt='' />
              <p>Заполните обязательные поля</p>
            </div>
          ) : (
            <></>
          )}
        </div>
      </>
    );
  } else if (deliveryConditions === 'EXW') {
    address = (
      <>
        <div className='whiteDeliveryDescrAndInput'>
          <p className='whiteDeliveryP'>Адрес отправления</p>
          <p className='whiteDeliveryP2'>Заполняется на Китайском языке</p>
          <input
            type='text'
            className='otherCategoryInput'
            placeholder='广州市白云区石井街道潭...'
            maxLength={25}
            onChange={(e) => {
              setDepartureAddress(e.target.value);
              setIsDepartureAddressNull(false);
            }}
            style={{
              border: isDepartureAddressNull
                ? '2px solid #FF4D00'
                : '2px solid #e0e8f1',
            }}
          />
        </div>
        <div className='whiteDeliveryDescrAndInput'>
          <p className='whiteDeliveryP'>Адрес назначения в РФ</p>
          <input
            type='text'
            className='otherCategoryInput'
            placeholder='Например, Москва'
            maxLength={25}
            onChange={(e) => {
              setDestinationAddress(e.target.value);
              setIsDestinationAddressNull(false);
            }}
            style={{
              border: isDestinationAddressNull
                ? '2px solid #FF4D00'
                : '2px solid #e0e8f1',
            }}
          />
          {isDestinationAddressNull || isDepartureAddressNull ? (
            <div className='fillingError'>
              <img src={alertRed} alt='' />
              <p>Заполните обязательные поля</p>
            </div>
          ) : (
            <></>
          )}
        </div>
      </>
    );
  } else if (deliveryConditions === 'FCA') {
    address = (
      <div className='whiteDeliveryDescrAndInput'>
        <p className='whiteDeliveryP'>Адрес назначения в РФ</p>
        <input
          type='text'
          className='otherCategoryInput'
          placeholder='Например, Москва'
          maxLength={25}
          onChange={(e) => {
            setDestinationAddress(e.target.value);
            setIsDestinationAddressNull(false);
          }}
          style={{
            border: isDestinationAddressNull
              ? '2px solid #FF4D00'
              : '2px solid #e0e8f1',
          }}
        />
        {isDestinationAddressNull ? (
          <div className='fillingError'>
            <img src={alertRed} alt='' />
            <p>Заполните обязательные поля</p>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  } else if (deliveryConditions === 'CFR') {
    address = (
      <div className='whiteDeliveryDescrAndInput'>
        <p className='whiteDeliveryP'>Адрес назначения в РФ</p>
        <input
          type='text'
          className='otherCategoryInput'
          placeholder='Например, Москва'
          maxLength={25}
          onChange={(e) => {
            setDestinationAddress(e.target.value);
            setIsDestinationAddressNull(false);
          }}
          style={{
            border: isDestinationAddressNull
              ? '2px solid #FF4D00'
              : '2px solid #e0e8f1',
          }}
        />
        {isDestinationAddressNull ? (
          <div className='fillingError'>
            <img src={alertRed} alt='' />
            <p>Заполните обязательные поля</p>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  } else if (deliveryConditions === 'CRT') {
    address = (
      <div className='whiteDeliveryDescrAndInput'>
        <p className='whiteDeliveryP'>Адрес назначения в РФ</p>
        <input
          type='text'
          className='otherCategoryInput'
          placeholder='Например, Москва'
          maxLength={25}
          onChange={(e) => {
            setDestinationAddress(e.target.value);
            setIsDestinationAddressNull(false);
          }}
          style={{
            border: isDestinationAddressNull
              ? '2px solid #FF4D00'
              : '2px solid #e0e8f1',
          }}
        />
        {isDestinationAddressNull ? (
          <div className='fillingError'>
            <img src={alertRed} alt='' />
            <p>Заполните обязательные поля</p>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  } else if (deliveryConditions === 'Не знаю') {
    address = (
      <>
        <div className='whiteDeliveryDescrAndInput'>
          <p className='whiteDeliveryP'>Подробно изложите вашу задачу</p>
          <textarea
            type='text'
            className='otherCategoryInput'
            placeholder='Введите сообщение'
            maxLength={400}
            rows='5'
            onChange={(e) => setTaskDescription(e.target.value)}
          />
        </div>
        <div className='whiteDeliveryDescrAndInput'>
          <p className='whiteDeliveryP'>Адрес назначения в РФ</p>
          <input
            type='text'
            className='otherCategoryInput'
            placeholder='Например, Москва'
            maxLength={25}
            onChange={(e) => {
              setDestinationAddress(e.target.value);
              setIsDestinationAddressNull(false);
            }}
            style={{
              border: isDestinationAddressNull
                ? '2px solid #FF4D00'
                : '2px solid #e0e8f1',
            }}
          />
          {isDestinationAddressNull ? (
            <div className='fillingError'>
              <img src={alertRed} alt='' />
              <p>Заполните обязательные поля</p>
            </div>
          ) : (
            <></>
          )}
        </div>
      </>
    );
  }

  return (
    <div className='whiteDeliveryInfo'>
      <form onSubmit={handleSubmit}>
        <div className='whiteDeliveryWhatCarrying'>
          <h2>Что везём?</h2>
          <div className='whiteDeliveryDescrAndInput'>
            <p className='whiteDeliveryP'>Тип контейнера</p>
            <div className='kargoCategotiesBtns'>
              <button
                type='button'
                onClick={() => {
                  setContainerType('20 ft');
                }}
                style={{
                  background: containerType === '20 ft' ? '#131416' : '#E0E8F1',
                  color: containerType === '20 ft' ? '#FF9900' : '#131416',
                }}
              >
                {containerType === '20 ft' ? (
                  <img src={containerOrange} alt='' />
                ) : (
                  <img src={containerBlack} alt='' />
                )}
                20 ft
              </button>
              <button
                type='button'
                onClick={() => {
                  setContainerType('40 ft');
                }}
                style={{
                  background: containerType === '40 ft' ? '#131416' : '#E0E8F1',
                  color: containerType === '40 ft' ? '#FF9900' : '#131416',
                }}
              >
                {containerType === '40 ft' ? (
                  <img src={containerOrange} alt='' />
                ) : (
                  <img src={containerBlack} alt='' />
                )}
                40 ft
              </button>
              <button
                type='button'
                onClick={() => {
                  setContainerType('Сборка');
                }}
                style={{
                  background:
                    containerType === 'Сборка' ? '#131416' : '#E0E8F1',
                  color: containerType === 'Сборка' ? '#FF9900' : '#131416',
                }}
              >
                Сборка
              </button>
            </div>
          </div>
          <div className='whiteDeliveryDescrAndInput'>
            <p className='whiteDeliveryP'>Описание груза</p>
            <input
              type='text'
              className='otherCategoryInput'
              placeholder='Например: заводы, газеты, пароходы'
              maxLength={25}
              onChange={(e) => {
                setCargoDescription(e.target.value);
                setIsCargoDescriptionNull(false);
              }}
              style={{
                border: isCargoDescriptionNull
                  ? '2px solid #FF4D00'
                  : '2px solid #e0e8f1',
              }}
            />
          </div>
          <div className='whiteDeliveryDescrAndInput'>
            <p className='whiteDeliveryP'>Вес и объем</p>
            <div className='whiteDeliveryWeight'>
              <div>
                <input
                  type='tel'
                  inputMode='numeric'
                  className='weightInput'
                  placeholder='Вес'
                  maxLength={6}
                  onChange={(e) => {
                    setWeight(+e.target.value);
                    setIsWeightNull(false);
                  }}
                  style={{
                    border: isWeightNull
                      ? '2px solid #FF4D00'
                      : '2px solid #e0e8f1',
                  }}
                />
                <div className='suffix'>кг</div>
                <input
                  type='tel'
                  inputMode='numeric'
                  className='volumeInput'
                  placeholder='Объем'
                  maxLength={6}
                  onChange={(e) => {
                    setVolume(+e.target.value);
                    setIsVolumeNull(false);
                  }}
                  style={{
                    border: isVolumeNull
                      ? '2px solid #FF4D00'
                      : '2px solid #e0e8f1',
                  }}
                />
                <div className='suffix'>м&#179;</div>
              </div>
            </div>
            {isCargoDescriptionNull || isWeightNull || isVolumeNull ? (
              <div className='fillingError'>
                <img src={alertRed} alt='' />
                <p>Заполните обязательные поля</p>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        {containerType !== 'Сборка' ? (
          <>
            <div className='whiteDeliveryСonditions'>
              <h2 className='whiteDeliveryСonditionsH'>Откуда и куда везём?</h2>
              <div className='whiteDeliveryDescrAndInput'>
                <p className='whiteDeliveryP'>Условия поставки</p>
                <div className='kargoCityBtns'>
                  <button
                    type='button'
                    onClick={() => {
                      setDeliveryConditions('FOB');
                    }}
                    style={{
                      background:
                        deliveryConditions === 'FOB' ? '#131416' : '#E0E8F1',
                      color:
                        deliveryConditions === 'FOB' ? '#FF9900' : '#131416',
                    }}
                  >
                    FOB
                  </button>
                  <button
                    type='button'
                    onClick={() => {
                      setDeliveryConditions('EXW');
                    }}
                    style={{
                      background:
                        deliveryConditions === 'EXW' ? '#131416' : '#E0E8F1',
                      color:
                        deliveryConditions === 'EXW' ? '#FF9900' : '#131416',
                    }}
                  >
                    EXW
                  </button>
                  <button
                    type='button'
                    onClick={() => {
                      setDeliveryConditions('FCA');
                    }}
                    style={{
                      background:
                        deliveryConditions === 'FCA' ? '#131416' : '#E0E8F1',
                      color:
                        deliveryConditions === 'FCA' ? '#FF9900' : '#131416',
                    }}
                  >
                    FCA
                  </button>
                  <button
                    type='button'
                    onClick={() => {
                      setDeliveryConditions('CFR');
                    }}
                    style={{
                      background:
                        deliveryConditions === 'CFR' ? '#131416' : '#E0E8F1',
                      color:
                        deliveryConditions === 'CFR' ? '#FF9900' : '#131416',
                    }}
                  >
                    CFR
                  </button>
                  <button
                    type='button'
                    onClick={() => {
                      setDeliveryConditions('CRT');
                    }}
                    style={{
                      background:
                        deliveryConditions === 'CRT' ? '#131416' : '#E0E8F1',
                      color:
                        deliveryConditions === 'CRT' ? '#FF9900' : '#131416',
                    }}
                  >
                    CRT
                  </button>
                  <button
                    type='button'
                    onClick={() => {
                      setDeliveryConditions('Не знаю');
                    }}
                    style={{
                      background:
                        deliveryConditions === 'Не знаю'
                          ? '#131416'
                          : '#E0E8F1',
                      color:
                        deliveryConditions === 'Не знаю'
                          ? '#FF9900'
                          : '#131416',
                    }}
                  >
                    Я не знаю, какой тип выбрать
                  </button>
                </div>
              </div>
              {deliveryConditions !== 'Не знаю' ? (
                <div className='deliveryDecr'>
                  <h2>{deliveryConditions}</h2>
                  <p>{deliveryDecr}</p>
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className='whiteDeliveryAddress'>{address}</div>
            <div className='whiteDeliveryProfitableServices'>
              <h2>Выгодные услуги</h2>
              <div className='whiteDeliveryDescrAndInput'>
                <p className='whiteDeliveryP'>
                  Агентируем, полностью сопровождаем в Китае?
                </p>
                <div className='kargoCityBtns'>
                  <button
                    type='button'
                    onClick={() => {
                      setAgency('Да');
                    }}
                    style={{
                      background: agency === 'Да' ? '#131416' : '#E0E8F1',
                      color: agency === 'Да' ? '#FF9900' : '#131416',
                    }}
                  >
                    Да
                  </button>
                  <button
                    type='button'
                    onClick={() => {
                      setAgency('Нет');
                    }}
                    style={{
                      background: agency === 'Нет' ? '#131416' : '#E0E8F1',
                      color: agency === 'Нет' ? '#FF9900' : '#131416',
                    }}
                  >
                    Нет
                  </button>
                  <button
                    type='button'
                    onClick={() => {
                      setAgency('Не уверен');
                    }}
                    style={{
                      background:
                        agency === 'Не уверен' ? '#131416' : '#E0E8F1',
                      color: agency === 'Не уверен' ? '#FF9900' : '#131416',
                    }}
                  >
                    Не уверен
                  </button>
                </div>
              </div>
              <div className='whiteDeliveryDescrAndInput'>
                <p className='whiteDeliveryP'>
                  Требуется ли таможенное оформление?
                </p>
                <div className='kargoCityBtns'>
                  <button
                    type='button'
                    onClick={() => {
                      setCustomsClearance('Да');
                    }}
                    style={{
                      background:
                        customsClearance === 'Да' ? '#131416' : '#E0E8F1',
                      color: customsClearance === 'Да' ? '#FF9900' : '#131416',
                    }}
                  >
                    Да
                  </button>
                  <button
                    type='button'
                    onClick={() => {
                      setCustomsClearance('Нет');
                    }}
                    style={{
                      background:
                        customsClearance === 'Нет' ? '#131416' : '#E0E8F1',
                      color: customsClearance === 'Нет' ? '#FF9900' : '#131416',
                    }}
                  >
                    Нет
                  </button>
                  <button
                    type='button'
                    onClick={() => {
                      setCustomsClearance('Не уверен');
                    }}
                    style={{
                      background:
                        customsClearance === 'Не уверен'
                          ? '#131416'
                          : '#E0E8F1',
                      color:
                        customsClearance === 'Не уверен'
                          ? '#FF9900'
                          : '#131416',
                    }}
                  >
                    Не уверен
                  </button>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className='whiteDeliveryAddress'>
              <h2>Откуда и куда везём?</h2>
              <div className='whiteDeliveryDescrAndInput'>
                <p className='whiteDeliveryP'>Адрес отправления</p>
                <p className='whiteDeliveryP2'>
                  Заполняется на Китайском языке
                </p>
                {dontKnowAddress ? (
                  <></>
                ) : (
                  <input
                    type='text'
                    className='otherCategoryInput'
                    placeholder='广州市白云区石井街道潭...'
                    maxLength={25}
                    onChange={(e) => {
                      setDepartureAddress(e.target.value);
                      setIsDepartureAddressNull(false);
                    }}
                    style={{
                      border: isDepartureAddressNull
                        ? '2px solid #FF4D00'
                        : '2px solid #e0e8f1',
                    }}
                  />
                )}
                <div className='kargoCityBtns'>
                  <button
                    type='button'
                    onClick={() => {
                      setDepartureAddress('Не знаю');
                      setDontKnowAddress(true);
                      setIsDepartureAddressNull(false);
                    }}
                    style={{
                      background:
                        departureAddress === 'Не знаю' ? '#131416' : '#E0E8F1',
                      color:
                        departureAddress === 'Не знаю' ? '#FF9900' : '#131416',
                    }}
                  >
                    Я не знаю адрес
                  </button>
                </div>
              </div>
              <div className='whiteDeliveryDescrAndInput'>
                <p className='whiteDeliveryP'>Адрес назначения в РФ</p>
                <input
                  type='text'
                  className='otherCategoryInput'
                  placeholder='Например, Москва'
                  maxLength={25}
                  onChange={(e) => {
                    setDestinationAddress(e.target.value);
                    setIsDestinationAddressNull(false);
                  }}
                  style={{
                    border: isDestinationAddressNull
                      ? '2px solid #FF4D00'
                      : '2px solid #e0e8f1',
                  }}
                />
                {isDestinationAddressNull || isDepartureAddressNull ? (
                  <div className='fillingError'>
                    <img src={alertRed} alt='' />
                    <p>Заполните обязательные поля</p>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className='whiteDeliveryProfitableServices'>
              <h2>Выгодные услуги</h2>
              <p className='whiteDeliveryP'>
                Требуется ли таможенное оформление?
              </p>
              <div className='kargoCityBtns'>
                <button
                  type='button'
                  onClick={() => {
                    setCustomsClearance('Да');
                  }}
                  style={{
                    background:
                      customsClearance === 'Да' ? '#131416' : '#E0E8F1',
                    color: customsClearance === 'Да' ? '#FF9900' : '#131416',
                  }}
                >
                  Да
                </button>
                <button
                  type='button'
                  onClick={() => {
                    setCustomsClearance('Нет');
                  }}
                  style={{
                    background:
                      customsClearance === 'Нет' ? '#131416' : '#E0E8F1',
                    color: customsClearance === 'Нет' ? '#FF9900' : '#131416',
                  }}
                >
                  Нет
                </button>
                <button
                  type='button'
                  onClick={() => {
                    setCustomsClearance('Не уверен');
                  }}
                  style={{
                    background:
                      customsClearance === 'Не уверен' ? '#131416' : '#E0E8F1',
                    color:
                      customsClearance === 'Не уверен' ? '#FF9900' : '#131416',
                  }}
                >
                  Не уверен
                </button>
              </div>
            </div>
          </>
        )}
        <div className='whiteDeliveryOtherInfo'>
          <h2>Дополнительная информация</h2>
          <textarea
            type='text'
            className='otherCategoryInput'
            placeholder='Введите сообщение'
            maxLength={400}
            rows='5'
            onChange={(e) => setOtherInfo(e.target.value)}
          />
        </div>

        <div className='kargoCalculate'>
          <button
            type='submit'
            onClick={handleCheck}
            className='kargoCalculateBtn'
          >
            Оставить заявку
          </button>
        </div>
      </form>
    </div>
  );
};

export default WhiteDelivery;
