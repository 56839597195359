import React, { useEffect, useState } from 'react';
import axios from 'axios';
import alert from '../img/alertBlack.svg';
import time from '../img/timeBlack.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import InputMask from 'react-input-mask';
import alertRed from '../img/alertRed.svg';
import Loader from '../components/Loader';

const Feedback = () => {
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const location = useLocation();

  const results = location.state || {};

  const [name, setName] = useState(location?.state?.fullname);
  const [phone, setPhone] = useState(location?.state?.phone);
  const [method, setMethod] = useState('Звонок');

  const data = {
    fullname: name,
    phone: phone,
    com_method: method,
    result: results?.result ?? results?.results,
  };

  const [isNameNull, setIsNameNull] = useState(false);
  const [isPhoneNull, setIsPhoneNull] = useState(false);

  const navigate = useNavigate();

  const handleCheck = () => {
    if (name === null || name === '') {
      setIsNameNull(true);
    }
    if (phone === null || phone === '') {
      setIsPhoneNull(true);
    }
  };

  function handleSubmit(e) {
    e.preventDefault();
    if (!isNameNull && !isPhoneNull) {
      axios
        .post('https://china.itflow.fun:1802/create_request', data)
        .then((response) => {
          navigate('/calculator/result/request/submit', {
            state: { reqId: response.data },
          });
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
  }

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className='feedback'>
          <div className='feedbackGray'>
            <div className='feedbackTop'>
              <form onSubmit={handleSubmit}>
                <h2>Заявка</h2>
                <div className='feedbackForm'>
                  <h3>Заполните форму для связи с менеджером</h3>

                  <div>
                    <p>Имя:</p>
                    <input
                      type='text'
                      className='otherCategoryInput'
                      placeholder='Как вас зовут?'
                      maxLength={25}
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                        setIsNameNull(false);
                      }}
                      style={{
                        border: isNameNull
                          ? '2px solid #FF4D00'
                          : '2px solid #e0e8f1',
                      }}
                    />
                  </div>
                  <div>
                    <p>Номер телефона:</p>
                    <InputMask
                      className='otherCategoryInput'
                      mask='+ 7 999 999-99-99'
                      placeholder='+7 ___ ___-__-__'
                      inputMode='numeric'
                      value={phone}
                      onChange={(e) => {
                        setPhone(e.target.value);
                        setIsPhoneNull(false);
                      }}
                      style={{
                        border: isPhoneNull
                          ? '2px solid #FF4D00'
                          : '2px solid #e0e8f1',
                      }}
                    />
                    {isNameNull || isPhoneNull ? (
                      <div className='fillingError'>
                        <img src={alertRed} alt='' />
                        <p>Заполните обязательные поля</p>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div>
                    <p>Удобный способ связи:</p>
                    <div className='feedbackMethodBtns'>
                      <button
                        type='button'
                        onClick={() => {
                          setMethod('Звонок');
                        }}
                        style={{
                          background:
                            method === 'Звонок' ? '#131416' : '#E0E8F1',
                          color: method === 'Звонок' ? '#FF9900' : '#131416',
                        }}
                      >
                        Звонок
                      </button>
                      <button
                        type='button'
                        onClick={() => {
                          setMethod('WhatsApp');
                        }}
                        style={{
                          background:
                            method === 'WhatsApp' ? '#131416' : '#E0E8F1',
                          color: method === 'WhatsApp' ? '#FF9900' : '#131416',
                        }}
                      >
                        WhatsApp
                      </button>
                      <button
                        type='button'
                        onClick={() => {
                          setMethod('Telegram');
                        }}
                        style={{
                          background:
                            method === 'Telegram' ? '#131416' : '#E0E8F1',
                          color: method === 'Telegram' ? '#FF9900' : '#131416',
                        }}
                      >
                        Telegram
                      </button>
                    </div>
                  </div>
                </div>
                <div className='feedbackConditions'>
                  <img src={alert} alt='' />
                  <p>
                    Нажимая кнопку «Отправить заявку», вы соглашаетесь с{' '}
                    <button
                      type='button'
                      onClick={() => {
                        navigate('/calculator/result/request/conditions', {
                          state: data,
                        });
                      }}
                    >
                      условиями
                    </button>{' '}
                    обработки персональных данных.
                  </p>
                </div>
                <div className='kargoCalculate'>
                  <button
                    type='submit'
                    className='kargoCalculateBtn'
                    onClick={handleCheck}
                  >
                    Отправить заявку
                  </button>
                  <div className='feedbackTime'>
                    <img src={time} alt='' />
                    <p>Среднее время ответа 15 минут</p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Feedback;
