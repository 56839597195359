import React, { useEffect, useState } from 'react';
import hide from '../img/hideOrange.svg';
import carWhite from '../img/carWhite.svg';
import carOrange from '../img/carOrange.svg';
import shipOrange from '../img/shipOrange.svg';
import shipWhite from '../img/shipWhite.svg';
import Kargo from '../components/Kargo';
import WhiteDelivery from '../components/WhiteDelivery';
import Loader from '../components/Loader';

const Calculator = () => {
  const [isKargo, setIsKargo] = useState('kargo');
  const [isActiveKargoDescr, setIsActiveKargoDescr] = useState(false);

  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <img
            className='homeBackground'
            src={require('../img/hero-img2.png')}
            alt='Калькулятор'
          />
          <div className='kargo'>
            <div className='kargoDark'>
              <div className='kargoDarkButtons'>
                <button
                  type='button'
                  onClick={() => {
                    setIsKargo('kargo');
                  }}
                  style={{
                    background: isKargo === 'kargo' ? '#FF9900' : 'transparent',
                    color: isKargo === 'kargo' ? '#FFFFFF' : '#FF9900',
                  }}
                >
                  {isKargo === 'kargo' ? (
                    <img src={carWhite} alt='' />
                  ) : (
                    <img src={carOrange} alt='' />
                  )}
                  Карго
                </button>
                <button
                  type='button'
                  onClick={() => {
                    setIsKargo('whiteDelivery');
                  }}
                  style={{
                    background:
                      isKargo === 'whiteDelivery' ? '#FF9900' : 'transparent',
                    color: isKargo === 'whiteDelivery' ? '#FFFFFF' : '#FF9900',
                  }}
                >
                  {isKargo === 'whiteDelivery' ? (
                    <img src={shipWhite} alt='' />
                  ) : (
                    <img src={shipOrange} alt='' />
                  )}
                  Белая доставка
                </button>
              </div>
              <div className='kargoDescr'>
                {isActiveKargoDescr ? (
                  <div className='kargoDescrHide'>
                    {isKargo === 'kargo' ? (
                      <p>
                        Доставка и таможенное оформление сборных грузов из Китая
                        в Казахстан, Киргизию, Беларусь с последующим транзитом
                        в Россию.
                      </p>
                    ) : (
                      <p>
                        Доставка и таможенная очистка в той стране в которой
                        будет последующая реализация товара. Купленный товар в
                        Китае и оплаченный по инвойсу из России, отправлений в
                        Россию после оплаты всех таможенных платежей будет
                        являться полностью белым грузом с прозрачными
                        документами
                      </p>
                    )}
                    <button
                      type='button'
                      onClick={() => {
                        setIsActiveKargoDescr(false);
                      }}
                    >
                      <div>
                        <img src={hide} alt='' />
                        Скрыть
                      </div>
                    </button>
                  </div>
                ) : (
                  <button
                    type='button'
                    onClick={() => {
                      setIsActiveKargoDescr(true);
                    }}
                  >
                    Что такое {isKargo === 'kargo' ? 'карго' : 'белая доставка'}
                    ?
                  </button>
                )}
              </div>
            </div>
            {isKargo === 'kargo' ? <Kargo /> : <WhiteDelivery />}
          </div>
        </div>
      )}
    </>
  );
};

export default Calculator;
