import React, { useCallback, useEffect, useState } from 'react';
import HomeButtons from '../components/HomeButtons';
import Loader from '../components/Loader';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

const RequestSubmitted = () => {
  const [isLoading, setLoading] = useState(true);

  const location = useLocation();

  const reqId = location?.state?.reqId || null;

  const WebApp = window.Telegram.WebApp;

  const data2 = {
    req_id: reqId,
    username: WebApp.initDataUnsafe.user?.username || 'Username not available',
    tg_id: WebApp.initDataUnsafe.user?.id || 'ID not available',
    name: WebApp.initDataUnsafe.user?.first_name || 'Name not available',
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    axios
      .post('https://china.itflow.fun:5454/order', data2)
      .then((response) => {
        console.log(data2);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className='requestSubmittedBg'>
          <div className='requestSubmitted'>
            <div className='requestSubmittedTop'>
              <img src={require('../img/message.png')} alt='' />
              <h2>Заявка отправлена</h2>
              <p>
                Пока менеджер обрабатывает заявку, ты можешь исследовать
                суперсилу наших услуг в каталоге!
              </p>
            </div>
            <HomeButtons />
          </div>
        </div>
      )}
    </>
  );
};

export default RequestSubmitted;
