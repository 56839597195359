import React, { useEffect, useState } from 'react';

import HomeButtons from '../components/HomeButtons';
import Loader from '../components/Loader';

const Home = () => {
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className='homePage'>
          <img
            className='homeBackground'
            src={require('../img/hero-img.png')}
            alt='IvanVeset'
          />
          <HomeButtons />
        </div>
      )}
    </>
  );
};

export default Home;
