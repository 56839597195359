import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import alert from '../img/alertOrange.svg';

const DeliveryTarrifs = () => {
  const location = useLocation();
  const navigate = useNavigate();

  function handleSubmit() {
    const path = location?.state?.isRequestPage
      ? '/calculator/result'
      : '/calculator';
    navigate(path, {
      state: location.state,
    });
  }

  var WebApp = window.Telegram.WebApp;
  var BackButton = WebApp.BackButton;
  BackButton.show();
  BackButton.onClick(function () {
    const path = location?.state?.isRequestPage
      ? '/calculator/result'
      : '/calculator';
    navigate(path, {
      state: location.state,
    });
    BackButton.hide();
  });

  return (
    <div className='request'>
      <div className='requestResults'>
        <div className='requestResultsTop'>
          <h2>Тарифы на выдачу грузов</h2>
        </div>
        <div className='insuranceTariff'>
          <div className='insuranceTariff1'>
            <p className='insuranceTariffP'>
              Все грузы карго по умолчанию приходят на рынки в Москву (Южные
              ворота, Люблино, Садовод).
            </p>
            <div className='deliveryTariffsWarning'>
              <h2 className='insuranceTariff1H'>Важно!</h2>
              <p className='insuranceTariffP'>
                Ни наша компания, ни китайский перевозчик не относятся никаким
                образом к работе рынков. Это отдельные организации,
                осуществляющие деятельность по своим правилам, и влиять на них
                возможности нет.
              </p>
            </div>
            <p className='insuranceTariffP'>
              Услуги и сборы рынка не включаются в стоимость накладных и
              оплачиваются отдельно при получении груза.
            </p>
            <h2 className='insuranceTariff1H'>
              На рынках взымается плата за такие услуги как:
            </h2>
            <div className='deliveryTariffsPrices'>
              <div className='deliveryTariffsPricesDiv'>
                <p>Рыночный сбор: за 1 куб м</p>
                <h2>600–700 ₽/куб</h2>
              </div>
              <div className='deliveryTariffsPricesDiv'>
                <p>Въезд на территорию (цена зависит от размера машины)</p>
                <h2>1 000–10 000 ₽</h2>
              </div>
              <div className='deliveryTariffsPricesDiv'>
                <p>Услуги погрузчика за паллет</p>
                <h2>500–1 000 ₽</h2>
              </div>
              <div className='deliveryTariffsPricesDiv'>
                <p>Услуги грузчиков ( за место)</p>
                <h2>100–350 ₽</h2>
              </div>
            </div>
          </div>
          <div className='requestPriceInfo'>
            <img src={alert} alt='' />
            <p>
              Цены регулируются рынками и могут отличатся от текущих. В случае
              проблем с выдачей можно связаться с представителем карго компании
              в Москве для разрешения спорных ситуаций.
            </p>
          </div>
          <div className='kargoCalculate'>
            <button
              type='submit'
              className='kargoCalculateBtn'
              onClick={handleSubmit}
            >
              Назад
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeliveryTarrifs;
