import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import alert from '../img/alertOrange.svg';

const InsuranceTariffs = () => {
  const location = useLocation();
  const navigate = useNavigate();

  function handleSubmit() {
    const path = location?.state?.isRequestPage
      ? '/calculator/result'
      : '/calculator';
    navigate(path, {
      state: location.state,
    });
  }

  var WebApp = window.Telegram.WebApp;
  var BackButton = WebApp.BackButton;
  BackButton.show();
  BackButton.onClick(function () {
    const path = location?.state?.isRequestPage
      ? '/calculator/result'
      : '/calculator';
    navigate(path, {
      state: location.state,
    });
    BackButton.hide();
  });

  return (
    <div className='request'>
      <div className='requestResults'>
        <div className='requestResultsTop'>
          <h2>Тарифы на страхование</h2>
        </div>
        <div className='insuranceTariff'>
          <div className='insuranceTariff1'>
            <p className='insuranceTariffP'>
              Страхование при использовании карго перевозки является не
              обязательным и защищает от полной потери, в редких случаях от
              порчи товара. Рассчитывается в процентах от стоимости товара по
              формуле:
            </p>
            <h2 className='insuranceTariff1H'>
              1 кг груза, его стоимость и максимальное покрытие
            </h2>
            <div className='insuranceTariffPrices'>
              <div className='insuranceTariffPricesDiv'>
                <h2>1%</h2>
                <p>1-30$</p>
              </div>
              <div className='insuranceTariffPricesDiv'>
                <h2>2%</h2>
                <p>30-60$</p>
              </div>
              <div className='insuranceTariffPricesDiv'>
                <h2>3%</h2>
                <p>60-100$</p>
              </div>
              <div className='insuranceTariffPricesDiv'>
                <h2>5%</h2>
                <p>100-200$</p>
              </div>
            </div>
          </div>
          <div className='requestPriceInfo'>
            <img src={alert} alt='' />
            <p>
              Если ваш товар весит 50 кг и его стоимость не более 1500$, то
              страхование обойдется в 1% от стоимости партии, и в случае
              частичной потери груза вы можете рассчитывать на возмещение до 30$
              за 1 кг потерянного груза.
            </p>
          </div>
          <div className='insuranceTariff3'>
            <p className='insuranceTariffP'>
              Нетоварный вид и царапины не являются основанием для возмещения
              ущерба, особенно если при выборе упаковки вы отказались от
              рекомендуемой и воспользовались более простым и дешевым способом
              упаковать свой груз. Груз, который был упакован на фабрике, без
              упаковки на складе карго, так же не будет застрахован от потери.
            </p>
            <p className='insuranceTariffP'>
              Объявленную ценность груза вы можете назначить на своё усмотрение,
              однако и возмещение потери будет согласно предоставленным данным.
            </p>
          </div>
        </div>

        <div className='kargoCalculate'>
          <button
            type='submit'
            className='kargoCalculateBtn'
            onClick={handleSubmit}
          >
            Назад
          </button>
        </div>
      </div>
    </div>
  );
};

export default InsuranceTariffs;
