import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const PackagingTariffs = () => {
  const [isActivePackage1Descr, setIsActivePackage1Descr] = useState(false);
  const [isActivePackage2Descr, setIsActivePackage2Descr] = useState(false);
  const [isActivePackage3Descr, setIsActivePackage3Descr] = useState(false);
  const [isActivePackage4Descr, setIsActivePackage4Descr] = useState(false);
  const [isActivePackage5Descr, setIsActivePackage5Descr] = useState(false);

  const navigate = useNavigate();

  const location = useLocation();

  function handleSubmit() {
    const path = location?.state?.isRequestPage
      ? '/calculator/result'
      : '/calculator';
    navigate(path, {
      state: location.state,
    });
  }

  var WebApp = window.Telegram.WebApp;
  var BackButton = WebApp.BackButton;
  BackButton.show();
  BackButton.onClick(function () {
    const path = location?.state?.isRequestPage
      ? '/calculator/result'
      : '/calculator';
    navigate(path, {
      state: location.state,
    });
    BackButton.hide();
  });

  return (
    <div className='request'>
      {isActivePackage1Descr ? (
        <div className='kargoCityDecr'>
          <div className='kargoCityDecrOpen'>
            <div
              className='kargoCityDecrClose'
              onClick={() => {
                setIsActivePackage1Descr(false);
              }}
            ></div>
            <div className='packageDecrTexts'>
              <h2>Мешок и скотч</h2>
              <div className='packagingDescrPrice'>
                <p className='packagingDescrPriceNum'>3$</p>
                <p className='packagingDescrPriceText'>Стоимость</p>
              </div>
              <ul>
                <li>влаго-пыле защита.</li>
              </ul>
              <p className='packagingP'>
                Данный вид упаковки защитит груз исключительно от пыли и дождя,
                подойдёт для изделий из ткани и изделий которым не страшны
                внешние давление и деформация.
              </p>
              <button
                type='button'
                onClick={() => {
                  setIsActivePackage1Descr(false);
                }}
              >
                Понятно
              </button>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      {isActivePackage2Descr ? (
        <div className='kargoCityDecr'>
          <div className='kargoCityDecrOpen'>
            <div
              className='kargoCityDecrClose'
              onClick={() => {
                setIsActivePackage2Descr(false);
              }}
            ></div>
            <div className='packageDecrTexts'>
              <h2>Паллет и деревянный каркас</h2>
              <div className='packagingDescrPrice'>
                <p className='packagingDescrPriceNum'>50$</p>
                <p className='packagingDescrPriceText'>Стоимость</p>
              </div>
              <ul>
                <li>влаго-пыле защита;</li>
                <li>защита от повреждений;</li>
                <li>защита от давления.</li>
              </ul>
              <p className='packagingP'>
                Универсальная упаковка для хоз товаров и товаров в
                индивидуальной упаковке, большая часть грузов формируется в
                паллеты при транспортировке, один из самых безопасных способов
                перевозки из Китая.
              </p>
              <button
                type='button'
                onClick={() => {
                  setIsActivePackage2Descr(false);
                }}
              >
                Понятно
              </button>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      {isActivePackage3Descr ? (
        <div className='kargoCityDecr'>
          <div className='kargoCityDecrOpen'>
            <div
              className='kargoCityDecrClose'
              onClick={() => {
                setIsActivePackage3Descr(false);
              }}
            ></div>
            <div className='packageDecrTexts'>
              <h2>Мешок и скотч + уголки из гофрокартона высокой плотности</h2>
              <div className='packagingDescrPrice'>
                <p className='packagingDescrPriceNum'>7$</p>
                <p className='packagingDescrPriceText'>Стоимость</p>
              </div>
              <ul>
                <li>влаго-пыле защита;</li>
                <li>защита от повреждений;</li>
                <li>защита от давления.</li>
              </ul>
              <p className='packagingP'>
                Преимущества данной упаковки в том, что она имеет низкий вес и
                значительно добавляет жесткости грузу, при этом практически не
                забирает плотность.
              </p>
              <button
                type='button'
                onClick={() => {
                  setIsActivePackage3Descr(false);
                }}
              >
                Понятно
              </button>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      {isActivePackage4Descr ? (
        <div className='kargoCityDecr'>
          <div className='kargoCityDecrOpen'>
            <div
              className='kargoCityDecrClose'
              onClick={() => {
                setIsActivePackage4Descr(false);
              }}
            ></div>
            <div className='packageDecrTexts'>
              <h2>Деревянный короб</h2>
              <div className='packagingDescrPrice'>
                <p className='packagingDescrPriceNum'>500¥</p>
                <p className='packagingDescrPriceText'>Стоимость</p>
              </div>
              <ul>
                <li>влаго-пыле защита;</li>
                <li>защита от деформации;</li>
                <li>защита от внешних воздействий.</li>
              </ul>
              <p className='packagingP'>
                Самая надёжная защита т.к деревянный каркас обивается фанерой и
                получается практически саркофаг.<br></br>
                Предназначена для особо ценного и хрупкого груза либо не
                имеющего ровные габариты (например заправочная станция).
              </p>
              <button
                type='button'
                onClick={() => {
                  setIsActivePackage4Descr(false);
                }}
              >
                Понятно
              </button>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      {isActivePackage5Descr ? (
        <div className='kargoCityDecr'>
          <div className='kargoCityDecrOpen'>
            <div
              className='kargoCityDecrClose'
              onClick={() => {
                setIsActivePackage5Descr(false);
              }}
            ></div>
            <div className='packageDecrTexts'>
              <h2>Мешок и скотч + пластиковые уголки</h2>
              <div className='packagingDescrPrice'>
                <p className='packagingDescrPriceNum'>4$</p>
                <p className='packagingDescrPriceText'>Стоимость</p>
              </div>
              <ul>
                <li>влаго-пыле защита;</li>
                <li>защита от повреждений;</li>
                <li>защита от деформаций.</li>
              </ul>
              <p className='packagingP'>
                Подходит для жёсткой картонной индивидуальной упаковки товара
                которую немного укрепив на углах пластиковыми накладками можно
                перевозить без паллет с целью экономии.
              </p>
              <button
                type='button'
                onClick={() => {
                  setIsActivePackage5Descr(false);
                }}
              >
                Понятно
              </button>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      <div className='requestResults'>
        <div className='requestResultsTop'>
          <h2>Тарифы на упаковку</h2>
        </div>
        <div className='packagingTariff'>
          <div
            className='packagingTariffTwo'
            onClick={() => {
              setIsActivePackage1Descr(true);
            }}
          >
            <img src={require('../img/truck.png')} alt='' />
            <div className='packagingTariffOneTitles'>
              <p>Мешок и скотч</p>
              <div className='packagingTariffOneBtn'>
                <h2>3$</h2>
                <button type='button'>?</button>
              </div>
            </div>
          </div>
          <div
            className='packagingTariffTwo'
            onClick={() => {
              setIsActivePackage2Descr(true);
            }}
          >
            <img src={require('../img/truck.png')} alt='' />
            <div className='packagingTariffOneTitles'>
              <p>Паллет и деревянный каркас</p>
              <div className='packagingTariffOneBtn'>
                <h2>50$</h2>
                <button type='button'>?</button>
              </div>
            </div>
          </div>
        </div>
        <div className='packagingTariff'>
          <div
            className='packagingTariffOne'
            onClick={() => {
              setIsActivePackage3Descr(true);
            }}
          >
            <img src={require('../img/truck.png')} alt='' />
            <div className='packagingTariffOneTitles'>
              <p>Мешок и скотч + уголки из гофрокартона высокой плотности</p>
              <div className='packagingTariffOneBtn'>
                <h2>7$</h2>
                <button type='button'>?</button>
              </div>
            </div>
          </div>
        </div>

        <div className='packagingTariff'>
          <div
            className='packagingTariffThree'
            onClick={() => {
              setIsActivePackage4Descr(true);
            }}
          >
            <img src={require('../img/truck.png')} alt='' />
            <div className='packagingTariffOneTitles'>
              <p>Деревянный короб</p>
              <div className='packagingTariffOneBtn'>
                <div>
                  <h2>500¥</h2>
                  <span>за м3</span>
                </div>
                <button type='button'>?</button>
              </div>
            </div>
          </div>
          <div
            className='packagingTariffThree'
            onClick={() => {
              setIsActivePackage5Descr(true);
            }}
          >
            <img src={require('../img/truck.png')} alt='' />
            <div className='packagingTariffOneTitles'>
              <p>Мешок и скотч + пластиковые уголки</p>
              <div className='packagingTariffOneBtn'>
                <div>
                  <h2>4$</h2>
                  <span>за место</span>
                </div>
                <button type='button'>?</button>
              </div>
            </div>
          </div>
        </div>
        <div className='kargoCalculate'>
          <button
            type='submit'
            className='kargoCalculateBtn'
            onClick={handleSubmit}
          >
            Назад
          </button>
        </div>
      </div>
    </div>
  );
};

export default PackagingTariffs;
