import React from 'react';
import { useNavigate } from 'react-router-dom';
import samoVycup from '../img/logoSamoVycup.svg';

const HomeButtons = () => {
  const navigate = useNavigate();
  const handleClickKargo = () => {
    navigate('/calculator');
  };

  return (
    <div className='home'>
      <h2>#Суперсила</h2>
      <div className='homeSuperpower'>
        <div
          className='homeKargo'
          onClick={() => {
            handleClickKargo();
          }}
        >
          <div className='homeKargoTexts'>
            <div className='homeKargoTexts2'>
              <div className='homeTitles'>Карго</div>
              <div className='homeDescription'>Найдём, купим, привезём</div>
            </div>
            <div className='homePrice'>от 300 000 &#8381;</div>
          </div>
          <img src={require('../img/truck.png')} alt='truck'></img>
        </div>
        <div className='homeLogisticsAndCustoms'>
          <div className='homeLogistics'>
            <div className='homeTitles'>Белая логистика</div>

            <div className='homePrice'>НДС, УСН</div>
          </div>
          <div className='homeCustoms'>
            <div className='homeTitles'>Таможня</div>
            <img src={require('../img/customs.png')} alt='customs'></img>
          </div>
        </div>
        <div className='homeCar'>
          <div>
            <div className='homeTitles'>Авто под заказ</div>
            <div className='homePrice'>Выгода до 30%</div>
          </div>
          <img src={require('../img/car.png')} alt='car'></img>
        </div>
      </div>
      <h2>#Супергероям</h2>
      <div className='homeSuperhero'>
        <div className='homeSamoVykup'>
          <img src={samoVycup} alt='' width='135px' />
          <div className='homePrice'>Для сильных и независимых</div>
        </div>
        <div className='homeChinaEscort'>
          <div className='homeTitles'>Сопровождение в Китае</div>
          <div className='homeDescription'>Встретим, покажем, накормим</div>
          <div className='homeArrow'>
            <p>→</p>
          </div>
        </div>
        <div className='homeSearch'>
          <div className='homeTitles'>Поиск товара на Футьене</div>
          <div className='homePrice'>Дешевле, чем 1688</div>
        </div>
        <div className='homeDeliveryAndFabric'>
          <div className='homeDelivery'>
            <div className='homeTitles'>Доставка образцов в Китай</div>
            <div className='homeArrow'>
              <p>→</p>
            </div>
          </div>
          <div className='homeFabric'>
            <div className='homeTitles'>Проверка товара на фабрике </div>
            <div className='homeArrow'>
              <p>→</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeButtons;
