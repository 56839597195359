import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import alert from '../img/alertGray.svg';
import alertRed from '../img/alertRed.svg';
import shirtBlack from '../img/shirtBlack.svg';
import shirtOrange from '../img/shirtOrange.svg';
import cookingBlack from '../img/cooking-potBlack.svg';
import cookingOrange from '../img/cooking-potOrange.svg';
import wrenchBlack from '../img/wrenchBlack.svg';
import wrenchOrange from '../img/wrenchOrange.svg';
import bikeBlack from '../img/bikeBlack.svg';
import bikeOrange from '../img/bikeOrange.svg';
import sprayBlack from '../img/sprayBlack.svg';
import sprayOrange from '../img/sprayOrange.svg';

const Kargo = () => {
  const [city, setCity] = useState(null);
  const [finalCity, setFinalCity] = useState(null);
  const [isActiveCityDescr, setIsActiveCityDescr] = useState(false);
  const [category, setCategory] = useState(null);
  const [isCategoryOther, setIsCategoryOther] = useState(false);
  const [podcategory, setPodcategory] = useState(null);
  const [weight, setWeight] = useState(0);
  const [volume, setVolume] = useState(0);

  const [isCityNull, setIsCityNull] = useState(false);
  const [isWeightNull, setIsWeightNull] = useState(false);
  const [isVolumeNull, setIsVolumeNull] = useState(false);
  const [isCategoryNull, setIsCategoryNull] = useState(false);
  const [isCategoryOtherNull, setIsCategoryOtherNull] = useState(false);
  const location = useLocation();

  const locationStateResults = location.state?.data || {};
  const data = {
    city,
    weight,
    volume,
    category,
    podcategory,
  };

  const navigate = useNavigate();

  const handleCheck = (path) => {
    if (!city || city === '') {
      setIsCityNull(true);
      window.scrollTo({
        top: 200,
        left: 0,
        behavior: 'smooth',
      });
    }
    if (
      !weight ||
      weight == 0 ||
      weight === '' ||
      weight < 0 ||
      isNaN(weight)
    ) {
      setIsWeightNull(true);
    }
    if (
      !volume ||
      volume == 0 ||
      volume === '' ||
      volume < 0 ||
      isNaN(volume)
    ) {
      setIsVolumeNull(true);
    }
    if (!category) {
      setIsCategoryNull(true);
    }
    if (isCategoryOther && category === 'Другое') {
      setIsCategoryOtherNull(true);
    }
    if (
      !(!city || city === '') &&
      !(!weight || weight === 0 || weight === '' || weight < 0) &&
      !(!volume || volume === 0 || volume === '' || volume < 0) &&
      category &&
      !(isCategoryOther && category === 'Другое') &&
      path
    ) {
      axios
        .post('https://china.itflow.fun:1802/process_calculation', data)
        .then((response) => {
          const results = response.data;
          navigate(path, {
            state: { results, data: { ...data, isCategoryOther } },
          });
        })
        .catch((error) => {
          console.error('Ошибка при отправке запроса:', error);
        });
    }
  };

  const handleClickPackaging = () => {
    navigate('/calculator/package', {
      state: { data: { ...data, isCategoryOther } },
    });
  };

  const handleClickInsurance = () => {
    navigate('/calculator/insurance', {
      state: { data: { ...data, isCategoryOther } },
    });
  };

  const handleClickDelivery = () => {
    navigate('/calculator/delivery', {
      state: { data: { ...data, isCategoryOther } },
    });
  };

  function handleSubmit(e) {
    e.preventDefault();

    handleCheck('/calculator/result');
  }

  useEffect(() => {
    if (locationStateResults) {
      const { city, weight, volume, category, isCategoryOther } =
        locationStateResults;
      setCity(city);
      if (city === 'Гуанчжоу') setFinalCity('Москва');
      if (city === 'Иу') setFinalCity('Москва');
      if (city === 'Суйфэньхе') setFinalCity('Уссурийск');
      setCategory(category);
      setIsCategoryOther(isCategoryOther);
      setWeight(weight);
      setVolume(volume);
    }
  }, []);

  return (
    <div className='kargoDeliveryInfo'>
      {isActiveCityDescr ? (
        <div className='kargoCityDecr'>
          <div className='kargoCityDecrOpen'>
            <div
              className='kargoCityDecrClose'
              onClick={() => {
                setIsActiveCityDescr(false);
              }}
            ></div>
            <div className='kargoCityDecrTexts'>
              <h2>
                Почему мы везём ваш груз в{' '}
                {finalCity === 'Москва' ? 'Москву' : 'Уссурийск'}?
              </h2>
              {finalCity === 'Москва' ? (
                <p>
                  Грузы, проходящие таможенную процедуру через страны
                  таможенного союза (Казахстан, Беларусь, Киргизия),
                  доставляются на рынки г. Москва (Люблино, Южные Ворота,
                  Садовод).
                </p>
              ) : (
                <p>
                  Карго-доставка из г. Суйфэньхе осуществляется до г. Уссурийск.
                </p>
              )}
              {finalCity === 'Москва' ? (
                <div className='kargoCityDecrTextsInfo'>
                  <img src={alert} alt='' />
                  <p>
                    Вывоз на ваш склад возможен при объеме более 25 куб. м. и
                    при предварительном согласовании.
                  </p>
                </div>
              ) : (
                <></>
              )}
              <button
                type='button'
                onClick={() => {
                  setIsActiveCityDescr(false);
                }}
              >
                Понятно
              </button>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      <form onSubmit={handleSubmit}>
        <div className='kargoCity'>
          <h2>Город отправления</h2>
          <div className='kargoCityBtns'>
            <button
              type='button'
              onClick={() => {
                setCity('Гуанчжоу');
                setFinalCity('Москва');
                setIsCityNull(false);
              }}
              style={{
                background: city === 'Гуанчжоу' ? '#131416' : '#E0E8F1',
                color: city === 'Гуанчжоу' ? '#FF9900' : '#131416',
              }}
            >
              Гуанчжоу
            </button>
            <button
              type='button'
              onClick={() => {
                setCity('Иу');
                setFinalCity('Москва');
                setIsCityNull(false);
              }}
              style={{
                background: city === 'Иу' ? '#131416' : '#E0E8F1',
                color: city === 'Иу' ? '#FF9900' : '#131416',
              }}
            >
              Иу
            </button>
            <button
              type='button'
              onClick={() => {
                setCity('Суйфэньхе');
                setFinalCity('Уссурийск');
                setIsCityNull(false);
              }}
              style={{
                background: city === 'Суйфэньхе' ? '#131416' : '#E0E8F1',
                color: city === 'Суйфэньхе' ? '#FF9900' : '#131416',
              }}
            >
              Суйфэньхе
            </button>
          </div>
          {city ? (
            <div className='kargoDeliveryFinalCity'>
              <p>Привезём в г. {finalCity}.</p>
              <button
                type='button'
                onClick={() => {
                  setIsActiveCityDescr(true);
                }}
              >
                Почему?
              </button>
            </div>
          ) : (
            <></>
          )}
          {isCityNull ? (
            <div className='fillingError'>
              <img src={alertRed} alt='' />
              <p>Выберите город отправления</p>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className='kargoWeight'>
          <h2>Вес и объем</h2>
          <div>
            <input
              type='tel'
              inputMode='decimal'
              className='weightInput'
              placeholder='Вес'
              value={weight === 0 ? '' : weight}
              maxLength={6}
              onChange={(e) => {
                setWeight(e.target.value.replace(',', '.'));
                setIsWeightNull(false);
              }}
              onFocus={() => {}}
              style={{
                border: isWeightNull
                  ? '2px solid #FF4D00'
                  : '2px solid #e0e8f1',
              }}
            />
            <div className='suffix'>кг</div>
            <input
              type='tel'
              inputMode='decimal'
              className='volumeInput'
              placeholder='Объем'
              value={volume === 0 ? '' : volume}
              maxLength={6}
              onChange={(e) => {
                setVolume(e.target.value.replace(',', '.'));
                setIsVolumeNull(false);
              }}
              onFocus={() => {}}
              style={{
                border: isVolumeNull
                  ? '2px solid #FF4D00'
                  : '2px solid #e0e8f1',
              }}
            />
            <div className='suffix'>м&#179;</div>
          </div>
          {isWeightNull || isVolumeNull ? (
            <div className='fillingError'>
              <img src={alertRed} alt='' />
              <p>Заполните обязательные поля</p>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className='kargoCategory'>
          <h2>Категория товара</h2>
          <div className='kargoCategotiesBtns'>
            <button
              type='button'
              onClick={() => {
                setCategory('cloths');
                setIsCategoryOther(false);
                setIsCategoryNull(false);
              }}
              style={{
                background: category === 'cloths' ? '#131416' : '#E0E8F1',
                color: category === 'cloths' ? '#FF9900' : '#131416',
              }}
            >
              {category === 'cloths' ? (
                <img src={shirtOrange} alt='' />
              ) : (
                <img src={shirtBlack} alt='' />
              )}
              Одежда
            </button>
            <button
              type='button'
              onClick={() => {
                setCategory('household');
                setIsCategoryOther(false);
                setIsCategoryNull(false);
              }}
              style={{
                background: category === 'household' ? '#131416' : '#E0E8F1',
                color: category === 'household' ? '#FF9900' : '#131416',
              }}
            >
              {category === 'household' ? (
                <img src={cookingOrange} alt='' />
              ) : (
                <img src={cookingBlack} alt='' />
              )}
              Хозтовары
            </button>
            <button
              type='button'
              onClick={() => {
                setCategory('autoparts');
                setIsCategoryOther(false);
                setIsCategoryNull(false);
              }}
              style={{
                background: category === 'autoparts' ? '#131416' : '#E0E8F1',
                color: category === 'autoparts' ? '#FF9900' : '#131416',
              }}
            >
              {category === 'autoparts' ? (
                <img src={wrenchOrange} alt='' />
              ) : (
                <img src={wrenchBlack} alt='' />
              )}
              Автозапчасти
            </button>
            <button
              type='button'
              onClick={() => {
                setCategory('motorcycles');
                setIsCategoryOther(false);
                setIsCategoryNull(false);
              }}
              style={{
                background: category === 'motorcycles' ? '#131416' : '#E0E8F1',
                color: category === 'motorcycles' ? '#FF9900' : '#131416',
              }}
            >
              {category === 'motorcycles' ? (
                <img src={bikeOrange} alt='' />
              ) : (
                <img src={bikeBlack} alt='' />
              )}
              Мототехника
            </button>
            <button
              type='button'
              onClick={() => {
                setCategory('cosmetics');
                setIsCategoryOther(false);
                setIsCategoryNull(false);
              }}
              style={{
                background: category === 'cosmetics' ? '#131416' : '#E0E8F1',
                color: category === 'cosmetics' ? '#FF9900' : '#131416',
              }}
            >
              {category === 'cosmetics' ? (
                <img src={sprayOrange} alt='' />
              ) : (
                <img src={sprayBlack} alt='' />
              )}
              Косметика
            </button>
            <button
              type='button'
              onClick={() => {
                setCategory('other');
                setIsCategoryOther(true);
                setIsCategoryNull(false);
              }}
              style={{
                background: isCategoryOther ? '#131416' : '#E0E8F1',
                color: isCategoryOther ? '#FF9900' : '#131416',
              }}
            >
              Другое
            </button>
          </div>
          {isCategoryOther ? (
            <input
              type='text'
              className='otherCategoryInput'
              placeholder='Опишите категорию товара'
              maxLength={25}
              value={podcategory}
              onChange={(e) => {
                setPodcategory(e.target.value);
                setIsCategoryOtherNull(false);
              }}
              style={{
                border: isCategoryOtherNull
                  ? '2px solid #FF4D00'
                  : '2px solid #e0e8f1',
              }}
            />
          ) : (
            <></>
          )}
          {isCategoryNull ? (
            <div className='fillingError'>
              <img src={alertRed} alt='' />
              <p>Выберите категорию товара</p>
            </div>
          ) : (
            <></>
          )}
          {isCategoryOther && isCategoryOtherNull ? (
            <div className='fillingError'>
              <img src={alertRed} alt='' />
              <p>Заполните обязательные поля</p>
            </div>
          ) : (
            <></>
          )}
        </div>

        <div className='kargoRates'>
          <h2>Тарифы</h2>
          <div
            className='kargoRatesItem'
            onClick={() => {
              handleClickPackaging();
            }}
          >
            <p>Упаковка</p>
            <div>→</div>
          </div>
          <div
            className='kargoRatesItem'
            onClick={() => {
              handleClickInsurance();
            }}
          >
            <p>Страхование</p>
            <div>→</div>
          </div>
          <div
            className='kargoRatesItem'
            onClick={() => {
              handleClickDelivery();
            }}
          >
            <p>Выдача груза</p>
            <div>→</div>
          </div>
        </div>
        <div className='kargoCalculate'>
          <button type='submit' className='kargoCalculateBtn'>
            Рассчитать
          </button>
        </div>
      </form>
    </div>
  );
};

export default Kargo;
