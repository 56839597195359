import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import shirt from '../img/shirtWhite.svg';
import cooking from '../img/cooking-potWhite.svg';
import wrench from '../img/wrenchWhite.svg';
import bike from '../img/bikeWhite.svg';
import spray from '../img/sprayWhite.svg';
import calendar from '../img/calendarOrange.svg';
import { TelegramShareButton } from 'react-share';
import share from '../img/shareOrange.svg';
import refresh from '../img/refreshOrange.svg';
import alert from '../img/alertOrange.svg';
import { useNavigate } from 'react-router-dom';
import Loader from '../components/Loader';

const Request = () => {
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const location = useLocation();

  const results = location.state.results || {};

  let icon, title;
  if (results.results.category === 'cloths') {
    icon = shirt;
    title = 'Одежда';
  } else if (results.results.category === 'household') {
    icon = cooking;
    title = 'Хозтовары';
  } else if (results.results.category === 'autoparts') {
    icon = wrench;
    title = 'Автозапчасти';
  } else if (results.results.category === 'motorcycles') {
    icon = bike;
    title = 'Мототехника';
  } else if (results.results.category === 'cosmetics') {
    icon = spray;
    title = 'Косметика';
  } else {
    title = results.results.podcategory;
  }

  let delivery, telegramTitle;

  delivery = (
    <div className='requestResultsDelivery'>
      <div className='requestFastDelivery'>
        <h2>Доставка</h2>
        <div className='requestDeliveryInfo'>
          <div>
            <p className='requestTotalPrice'>
              {results.results.price?.toLocaleString('ru')}$
            </p>
            <p className='requestPriceKg'>{results.results.cost}$ за 1 кг</p>
          </div>
          <p className='requestTime'>
            <img src={calendar} alt='React Logo' />
            {results.results.days} дн.
          </p>
        </div>
      </div>
    </div>
  );
  telegramTitle = `\nГород отправления – ${results.results.city}\nВес – ${
    results.results.weight
  } кг\nОбъем – ${
    results.results.volume
  } м\nКатегория – ${title}\n\nДоставка ${results.results.price?.toLocaleString(
    'ru'
  )}$ (${results.results.cost}$ за 1 кг) \n${results.results.days} дн.`;

  const url = 'Результаты расчета от Иван Везёт';

  const navigate = useNavigate();

  const handleClickPackaging = () => {
    navigate('/calculator/package', {
      state: { ...location.state, isRequestPage: true },
    });
  };

  const handleClickInsurance = () => {
    navigate('/calculator/insurance', {
      state: { ...location.state, isRequestPage: true },
    });
  };

  const handleClickDelivery = () => {
    navigate('/calculator/delivery', {
      state: { ...location.state, isRequestPage: true },
    });
  };

  function handleSubmit(e) {
    navigate('/calculator/result/request', {
      state: { ...location.state, isRequestPage: true },
    });
  }

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className='request'>
          <div className='requestResults'>
            <div className='requestResultsTop'>
              <h2>Результаты расчета</h2>
              <div className='requestParams'>
                <p>{results.results.city}</p>
                <p>{results.results.weight} кг</p>
                <p>{results.results.volume} м&#179;</p>
                <p>
                  {results.results.category === 'cloths' ||
                  results.results.category === 'household' ||
                  results.results.category === 'autoparts' ||
                  results.results.category === 'motorcycles' ||
                  results.results.category === 'cosmetics' ? (
                    <img src={icon} alt='' />
                  ) : (
                    <></>
                  )}
                  {title}
                </p>
              </div>
            </div>
            {delivery}
            <div className='requestShare'>
              <TelegramShareButton
                url={url}
                className='share-button'
                title={telegramTitle}
              >
                <img src={share} alt='' />
                Поделиться
              </TelegramShareButton>
              <button
                type='button'
                onClick={() => {
                  navigate('/calculator');
                }}
              >
                <img src={refresh} alt='' />
                Рассчитать еще
              </button>
            </div>
            <div className='requestPriceInfo'>
              <img src={alert} alt='' />
              <p>
                Стоимость не включает в себя страхование и упаковку. Тарифы
                смотрите ниже.
              </p>
            </div>
            <div className='kargoRates'>
              <h2>Тарифы</h2>
              <div
                className='kargoRatesItem'
                onClick={() => {
                  handleClickPackaging();
                }}
              >
                <p>Упаковка</p>
                <div>→</div>
              </div>
              <div
                className='kargoRatesItem'
                onClick={() => {
                  handleClickInsurance();
                }}
              >
                <p>Страхование</p>
                <div>→</div>
              </div>
              <div
                className='kargoRatesItem'
                onClick={() => {
                  handleClickDelivery();
                }}
              >
                <p>Выдача груза</p>
                <div>→</div>
              </div>
            </div>
            <div className='kargoCalculate'>
              <button
                type='submit'
                className='kargoCalculateBtn'
                onClick={handleSubmit}
              >
                Связаться с менеджером
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Request;
